import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  Button,
  Div,
  Group,
  Placeholder,
  Select,
  Spinner,
  Text,
  Title,
} from '@vkontakte/vkui';
import { Icon24ListDeleteOutline, Icon24Add } from '@vkontakte/icons';
import { ITableHeader } from 'lib/types';
import { ApiResponse } from 'lib/api/api';
import { parseFiltersUrl } from 'lib/utils/parseFunction';
import { IPerson } from './types.persons';
import { personFilters, personHeaders } from './constants.persons';
import TableSetup from 'components/common/TableSetup';
import CustomSortIcon from 'components/common/CustomSortIcon';
import useFetch from 'lib/hooks/useFetch';
import useParams from 'lib/hooks/useParams';
import FiltersWrapper from 'components/common/FiltersWrapper';
import FiltersButton from 'components/common/FiltersButton';
import FormItemPanel from 'components/common/FormItemPanel';
import PaginationComponent from 'components/common/Pagination';
import { ADMIN_PERSONS_ROUTE } from 'lib/constants';
import InputSearch from 'components/common/InputSearch';

const Persons: FC = () => {
  const [activeHeaders, setActiveHeaders] = useState<ITableHeader[]>([]);
  const { data, loading, fetchData } = useFetch<ApiResponse<IPerson[]>>();
  const location = useLocation();
  const {
    filters,
    setFilters,
    addFiltersAndUpdateUrl,
    reset,
    handleChange,
    openFilters,
    handletoggleFilters,
    setSortingFunc,
    sorting,
    currentPage,
    setCurrentPage,
  } = useParams(personFilters);
  const { has_preview_url, is_enabled } = filters;
  const [persons, setPersons] = useState<IPerson[]>([]);

  const handleResetFilters = useCallback(() => {
    reset();
  }, [reset]);

  const fetchListFunction = () => {
    const queryParams = location.search ? parseFiltersUrl(location.search) : '';

    fetchData({
      path: `/person/persons/?page=${currentPage - 1}${queryParams && `&${queryParams}`}`,
      method: 'get',
    });
  };

  const handleChangeSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilters({ ...filters, q: value });
  };

  useEffect(() => {
    fetchListFunction();
  }, [location.search, currentPage]);

  useEffect(() => {
    if (!loading && data?.results) {
      setPersons(data.results);
    }
  }, [loading, location.search]);

  useEffect(() => {
    const storedHeaders = localStorage.getItem('columnOrder_persons');

    if (storedHeaders) {
      setActiveHeaders(JSON.parse(storedHeaders));
    } else {
      setActiveHeaders(personHeaders);
    }
  }, []);

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <Group>
          <Div className="flex items-center gap-2">
            <Link to={ADMIN_PERSONS_ROUTE + `/addNew`}>
              <Button
                mode="primary"
                className="vkui-edit-button-primary min-w-fit"
                size="m"
                before={<Icon24Add />}
              >
                Добавить
              </Button>
            </Link>
            <InputSearch
              onSubmit={addFiltersAndUpdateUrl}
              value={filters.q as string}
              onChange={handleChangeSearchValue}
            />
            <FiltersButton
              openFilters={openFilters}
              toggle={handletoggleFilters}
            />
            <TableSetup
              tableId="persons"
              headers={personHeaders}
              onActiveHeadersChange={setActiveHeaders}
            />
          </Div>
          {openFilters && (
            <FiltersWrapper
              reset={handleResetFilters}
              addFiltersAndUpdateUrl={addFiltersAndUpdateUrl}
            >
              <FormItemPanel top="Аватар" htmlFor="has_preview_url">
                <Select
                  className="vkui-select"
                  id="has_preview_url"
                  name="has_preview_url"
                  placeholder="Наличие аватара"
                  value={has_preview_url as string}
                  onChange={handleChange}
                  allowClearButton
                  options={[
                    { value: 'true', label: 'Есть' },
                    { value: 'false', label: 'Нет' },
                  ]}
                />
              </FormItemPanel>
              <FormItemPanel top="Статус" htmlFor="is_enabled">
                <Select
                  className="vkui-select"
                  id="is_enabled"
                  name="is_enabled"
                  placeholder="Укажите статус"
                  value={is_enabled as string}
                  onChange={handleChange}
                  allowClearButton
                  options={[
                    { value: 'true', label: 'Активна' },
                    { value: 'false', label: 'Неактивна' },
                  ]}
                />
              </FormItemPanel>
            </FiltersWrapper>
          )}
          {persons && persons.length ? (
            <>
              <div className="custom-scrollbar overflow-auto pb-2">
                <table className="w-full">
                  <thead>
                    <tr className="border-y-1 border-color-steel-gray-80">
                      {activeHeaders.map((header) => (
                        <th
                          key={header.value}
                          className="text-color-gray-600 uppercase py-4 whitespace-nowrap"
                        >
                          <Title
                            className="flex text-sm ml-8 max-w-fit gap-1 items-center cursor-pointer"
                            level="2"
                            onClick={() => {
                              if (header.sortingValue) {
                                setSortingFunc(header.sortingValue);
                              }
                            }}
                          >
                            {header.sortingValue && (
                              <CustomSortIcon
                                sortDirection={sorting}
                                activeHeader={header}
                              />
                            )}
                            {header.label}
                          </Title>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {persons &&
                      persons.map(
                        ({ id, full_name, preview_url, is_enabled }) => (
                          <tr
                            onClick={() =>
                              window.open(
                                ADMIN_PERSONS_ROUTE + `/edit/${id}`,
                                '_blank',
                              )
                            }
                            key={id}
                            className="cursor-pointer transition-colors hover:bg-color-steel-gray-20 h-10"
                          >
                            {activeHeaders.some((h) => h.value === 'id') && (
                              <td className="py-2" id="id">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {id.split('-').pop() || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'full_name',
                            ) && (
                              <td className="py-2" id="full_name">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {full_name || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'preview_url',
                            ) && (
                              <td className="py-2" id="preview_url">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {preview_url ? 'Есть' : 'Нет'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'is_enabled',
                            ) && (
                              <td className="py-2" id="is_enabled">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {is_enabled ? 'Активна' : 'Неактивна'}
                                </Text>
                              </td>
                            )}
                          </tr>
                        ),
                      )}
                  </tbody>
                </table>
              </div>
              {data && data?.count > 10 ? (
                <PaginationComponent
                  count={data?.count}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  items={persons}
                />
              ) : null}
            </>
          ) : (
            <Div>
              <Placeholder
                icon={<Icon24ListDeleteOutline />}
                header={'Пользователи не найдены'}
              >
                Попробуйте изменить критерии поиска
              </Placeholder>
            </Div>
          )}
        </Group>
      )}
    </>
  );
};

export default Persons;
