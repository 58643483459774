export const personHeaders = [
  {
    order: 1,
    label: 'ID',
    value: 'id',
  },
  {
    order: 2,
    label: 'ФИО',
    value: 'full_name',
  },
  {
    order: 3,
    label: 'Аватар',
    value: 'preview_url',
  },
  {
    order: 4,
    label: 'Активность',
    value: 'is_enabled',
  },
];

export const personFilters = {
  q: '',
  has_preview_url: '',
  is_enabled: '',
};

export const personBlank = {
  id: '',
  slug: '',
  full_name: '',
  birthday: '',
  description: '',
  vk_page: '',
  preview_url: '',
  preview_url_original: '',
  image_urls: [],
  is_enabled: false,
};