import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Div,
  Group,
  Title,
  FormItem,
  Spinner,
  Button,
  ButtonGroup,
  DateInput,
  LocaleProvider,
  Link,
  Image,
  File,
  ModalPage,
  ModalRoot,
  ModalCard,
  Text,
  Checkbox,
} from '@vkontakte/vkui';
import {
  Icon24ExternalLinkOutline,
  Icon20DeleteOutline,
  Icon20CropOutline,
  Icon20PicturePlusOutline,
  Icon20StorySlashOutline,
} from '@vkontakte/icons';
import { IPersonEdit } from './types.persons';
import { useSnackbar } from 'lib/hooks/useSnackbar';
import useFetch from 'lib/hooks/useFetch';
import PanelTitle from 'components/common/PanelTitle';
import EventFormItem from 'components/common/EventFormItem';
import { personBlank } from './constants.persons';
import useFormItems from 'lib/hooks/useFormItems';
import ReactQuill from 'react-quill';
import { ADMIN_PERSONS_ROUTE, MIN_DIMENSION } from 'lib/constants';
import { findObjectsDiffs } from 'lib/utils/findObjectsDiffs';
import { replaceEmptyWithNull } from 'lib/utils/replaceEmptyWithNull';
import { textEditorFormats, textEditorModules } from 'lib/utils/textEditor';
import { getClearUrl } from 'lib/utils/getClearUrl';
import { handleFileSelection, handleImageUrl } from 'lib/utils/imageFile.utils';
import ImageCropper from 'components/common/ImageCropper';
import { formatDateTo } from 'lib/utils/date.utils';
import { getUserRole } from 'lib/utils/authUtils';

const PersonsEdit: React.FC = () => {
  const userRole = getUserRole();
  const navigate = useNavigate();
  const [personData, setPersonData] = useState<IPersonEdit>(personBlank);
  const { personId, methodType } = useParams();
  const { setSnackbarContent } = useSnackbar();
  const { data, loading, fetchData } = useFetch<IPersonEdit>();
  const { fetchData: patchData } = useFetch();
  const [newDescription, setNewDescription] = useState<string>('');
  const [hasNewDescription, setHasNewDescription] = useState<boolean>(false);
  const [newBirthday, setNewBirthday] = useState<Date | undefined>(undefined);
  const [openModal, setOpenModal] = useState<string>('');
  const [imageSrc, setImageSrc] = useState<string>('');
  const [newCover, setNewCover] = useState<string>('');
  const [newCoverOriginal, setNewCoverOriginal] = useState<string>('');
  const [cropError, setCropError] = useState<string>('');
  const [isNewEnabled, setIsNewEnabled] = useState<boolean>(false);

  const { formData, handleChange, isError, errorItems } =
    useFormItems<IPersonEdit>({
      initialValues: personData,
    });

  const { id, full_name, vk_page, slug } = formData;

  const handleSave = async () => {
    if (methodType === 'edit') {
      const diffObject = findObjectsDiffs(formData, personData);
      const diffKeys = Object.keys(diffObject);
      // eslint-disable-next-line
      const diffData: Record<string, any> = {};

      if (diffKeys.length !== 0) {
        diffKeys.forEach((key) => {
          diffData[key] = formData[key as keyof IPersonEdit];
        });
      }

      if (hasNewDescription && personData.description !== newDescription) {
        diffData.description = newDescription;
      }

      if (new Date(personData.birthday) !== newBirthday) {
        diffData.birthday = newBirthday
          ? formatDateTo({
              date: newBirthday,
              format: 'dayFrom',
            })
          : null;
      }

      if (personData?.is_enabled !== isNewEnabled)
        diffData.is_enabled = isNewEnabled;

      if (personData.preview_url !== newCover) {
        diffData.preview_url = newCover;

        if (
          newCoverOriginal !== '' &&
          personData.preview_url_original !== newCoverOriginal
        ) {
          diffData.preview_url_original = newCoverOriginal;
        }

        if (
          newCoverOriginal === '' &&
          personData.preview_url_original !== newCoverOriginal
        ) {
          diffData.preview_url_original = '';
        }
      }

      if (Object.keys(diffData).length > 0) {
        const editPromise = patchData({
          path: `/person/persons/${personId}/`,
          method: 'patch',
          body: replaceEmptyWithNull(diffData),
          onSuccessMessage: 'Изменения сохранены',
        });

        const response = await editPromise;
        const error = response?.errorData?.response?.data?.detail;

        if (error?.code) {
          setSnackbarContent({
            type: 'error',
            message: error.error_message,
          });
        } else {
          await fetchDataFunction();
        }
      }
    }

    if (methodType === 'addNew') {
      // eslint-disable-next-line
      const { id, ...diffData } = formData;

      if (newBirthday)
        diffData.birthday = formatDateTo({
          date: newBirthday,
          format: 'dayFrom',
        });

      if (newDescription) diffData.description = newDescription;

      diffData.is_enabled = true;

      if (personData.preview_url !== newCover) {
        diffData.preview_url = newCover;

        if (
          newCoverOriginal !== '' &&
          personData.preview_url_original !== newCoverOriginal
        ) {
          diffData.preview_url_original = newCoverOriginal;
        }

        if (
          newCoverOriginal === '' &&
          personData.preview_url_original !== newCoverOriginal
        ) {
          diffData.preview_url_original = '';
        }
      }

      const addPromise = patchData({
        path: `/person/persons/`,
        method: 'post',
        body: replaceEmptyWithNull(diffData),
        onSuccessMessage: 'Успешно добавлено',
      });

      const response = await addPromise;
      const error = response?.errorData?.response?.data?.detail;

      if (error?.code) {
        setSnackbarContent({
          type: 'error',
          message: error.error_message,
        });
      } else {
        navigate(`${ADMIN_PERSONS_ROUTE}`);
      }
    }
  };

  const handleIsNewEnabledChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsNewEnabled(event.target.checked);
  };

  const fetchDataFunction = () => {
    if (methodType === 'edit') {
      fetchData({ path: `/person/persons/${personId}/`, method: 'get' });
    }
  };

  const onCoverSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;

    handleFileSelection({
      minDimension: MIN_DIMENSION,
      aspectRatio: 1 / 1,
      file: file,
      setCropError: setCropError,
      setImageSrc: setImageSrc,
      setOpenModal: setOpenModal,
      setSnackbarContent: setSnackbarContent,
      setOriginal: setNewCoverOriginal,
    });
  };

  const handleDeletePerson = () => {
    fetchData({
      path: `/person/persons/${personId}/`,
      method: 'delete',
      onSuccessMessage: 'Успешно удалено',
    }).then(() => navigate(ADMIN_PERSONS_ROUTE));
  };

  useEffect(() => {
    fetchDataFunction();
  }, []);

  useEffect(() => {
    if (methodType === 'edit') {
      if (!loading && data) {
        setPersonData(data);
      }
    }
  }, [loading]);

  useEffect(() => {
    if (personData.preview_url_original) {
      handleImageUrl(
        MIN_DIMENSION,
        1 / 1,
        personData.preview_url_original,
        setCropError,
        setImageSrc,
      );
    }

    personData.description && setNewDescription(personData.description);

    setNewCover(personData.preview_url);
    setNewCoverOriginal(personData.preview_url_original);

    personData.is_enabled && setIsNewEnabled(personData.is_enabled);

    if (personData.birthday) {
      const dateObj = new Date(personData.birthday);
      dateObj && setNewBirthday(dateObj);
    }
  }, [personData]);

  useEffect(() => {
    newDescription === '<p><br></p>' && setNewDescription('');
  }, [newDescription]);

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <>
          <PanelTitle>
            <Title className="text-color-black">Детали персоны</Title>
            <span className="text-color-black text-3xl">-</span>
            <Title className="inline text-color-steel-gray-500">
              {full_name}
            </Title>
          </PanelTitle>
          <Group className="custom-scrollbar">
            {methodType === 'edit' && (
              <>
                <FormItem top="Ссылка">
                  {!slug ? (
                    <div>Ссылка не сформирована</div>
                  ) : process.env.REACT_APP_ENV === 'develop' ? (
                    <div className="flex flex-col">
                      <span className="text-sm">{slug}</span>
                      <span className="text-xs text-gray-500">
                        (В текущем окружении нет ссылки на WEB)
                      </span>
                    </div>
                  ) : (
                    <Link
                      href={getClearUrl('person', '-', slug)}
                      target="_blank"
                    >
                      {slug}
                      <Icon24ExternalLinkOutline
                        width={18}
                        height={18}
                        className="mb-1"
                      />
                    </Link>
                  )}
                </FormItem>
                <EventFormItem
                  className="vkui-input"
                  top="ID"
                  name="id"
                  value={id || ''}
                  onChange={handleChange}
                  placeholder="Укажите ID"
                  isRequired={true}
                  isError={isError}
                  errorItems={errorItems}
                  readOnly
                />
              </>
            )}
            <FormItem top="Аватар" className="w-72">
              <div className="py-4">
                {newCover ? (
                  <Image
                    className="group m-0 p-0"
                    size={96}
                    src={newCover}
                    onClick={() => setOpenModal('coverPreview')}
                  >
                    <Div className="m-2 p-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <Image.Badge background={'stroke'} className="bg-white">
                        <Icon20DeleteOutline
                          color={'#99A2AD'}
                          onClick={(event) => {
                            event.stopPropagation();
                            setOpenModal('deleteCover');
                          }}
                          className="cursor-pointer"
                        />
                      </Image.Badge>
                    </Div>
                  </Image>
                ) : (
                  <Image className="group m-0 p-0" size={96}>
                    <Icon20StorySlashOutline />
                  </Image>
                )}
              </div>
              <div className="flex flex-row gap-3">
                {imageSrc && (
                  <Button
                    className="bg-color-blue-button"
                    before={<Icon20CropOutline width={18} className="ml-0.5" />}
                    mode="secondary"
                    size="l"
                    appearance="accent"
                    accept="image/*"
                    onClick={() => setOpenModal('crop')}
                  >
                    Изменить
                  </Button>
                )}

                <File
                  before={
                    <Icon20PicturePlusOutline width={18} className="ml-0.5" />
                  }
                  mode="secondary"
                  size="l"
                  appearance="accent"
                  accept="image/*"
                  onChange={onCoverSelect}
                >
                  Новый
                </File>
              </div>
            </FormItem>
            <EventFormItem
              className="vkui-input"
              top="ФИО"
              name="full_name"
              value={full_name}
              onChange={handleChange}
              placeholder="Укажите ФИО"
              isRequired={true}
              isError={isError}
              errorItems={errorItems}
            />
            {openModal === '' && (
              <FormItem top="Дата рождения" className="w-72">
                <LocaleProvider value={'ru'}>
                  <DateInput
                    value={newBirthday}
                    onChange={setNewBirthday}
                    closeOnChange={true}
                    className="z-9"
                  />
                </LocaleProvider>
              </FormItem>
            )}
            <EventFormItem
              className="vkui-input"
              top="Ссылка на странице ВК"
              name="vk_page"
              value={vk_page || ''}
              onChange={handleChange}
              placeholder="Укажите ссылку"
              isRequired={true}
              isError={isError}
              errorItems={errorItems}
            />
            <FormItem top="Описание">
              <ReactQuill
                className="text-editor"
                value={newDescription}
                onChange={(value) => setNewDescription(value)}
                onChangeSelection={() => setHasNewDescription(true)}
                modules={textEditorModules}
                formats={textEditorFormats}
              />
            </FormItem>
            {methodType === 'edit' && (
              <FormItem className="py-0 mt-3 mb-2 ml-3 w-32">
                <Checkbox
                  checked={isNewEnabled}
                  onChange={handleIsNewEnabledChange}
                  disabled={userRole === 'support'}
                >
                  Активна
                </Checkbox>
              </FormItem>
            )}
            <Div>
              <ButtonGroup align="right" stretched>
                {methodType === 'edit' ? (
                  <>
                    <Button
                      className="vkui-edit-button-secondary"
                      mode="secondary"
                      size="m"
                      appearance="accent"
                      onClick={() => setOpenModal('delete')}
                    >
                      Удалить
                    </Button>
                    <Button
                      className="vkui-edit-button-primary"
                      mode="primary"
                      size="m"
                      appearance="accent"
                      onClick={handleSave}
                    >
                      Сохранить
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => navigate(`${ADMIN_PERSONS_ROUTE}`)}
                      className="vkui-edit-button-secondary"
                      mode="secondary"
                      size="m"
                      appearance="accent"
                    >
                      Назад
                    </Button>
                    <Button
                      className="vkui-edit-button-primary"
                      mode="primary"
                      size="m"
                      appearance="accent"
                      onClick={handleSave}
                    >
                      Добавить
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </Div>
          </Group>
        </>
      )}
      <ModalRoot activeModal={openModal}>
        <ModalPage
          className="fixed z-20"
          id="crop"
          onClose={() => setOpenModal('')}
          settlingHeight={100}
          height={'70%'}
          size={'l'}
        >
          {cropError && <p className="text-red-400">{cropError}</p>}
          <ImageCropper
            minDimension={MIN_DIMENSION}
            aspectRatio={1 / 1}
            imageSrc={imageSrc}
            setOpenModal={setOpenModal}
            setState={setNewCover}
          />
        </ModalPage>
        <ModalCard
          className="fixed"
          id="deleteCover"
          onClose={() => setOpenModal('')}
        >
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black mb-2" level="2">
              Удаление обложки
            </Title>
            <Text className="text-color-gray-600 mb-6">
              Вы действительно хотите удалить обложку?
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={() => setOpenModal('')}
              >
                Назад
              </Button>
              <Button
                mode="primary"
                className="vkui-edit-button-error"
                size="m"
                onClick={() => {
                  setNewCover('');
                  setNewCoverOriginal('');
                  setOpenModal('');
                }}
              >
                Удалить
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard>
        <ModalCard id="delete" onClose={() => setOpenModal('')}>
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black" level="2">
              Удаление персоны
            </Title>
            <Text className="text-color-gray-600">
              Данная персона будет удалена
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={() => setOpenModal('')}
              >
                Отмена
              </Button>
              <Button
                className="vkui-edit-button-primary"
                mode="primary"
                size="m"
                onClick={() => {
                  setOpenModal('');
                  handleDeletePerson();
                }}
              >
                Удалить
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard>
      </ModalRoot>
    </>
  );
};

export default PersonsEdit;
