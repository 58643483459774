import React, { FC, ChangeEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Checkbox,
  Div,
  FormItem,
  Group,
  ModalPage,
  ModalRoot,
  Select,
  Spinner,
  Title,
} from '@vkontakte/vkui';
import { ApiResponse } from 'lib/api/api';
import { useInfiniteScroll } from 'lib/hooks/useInfiniteScroll';
import { IChip, ICity } from 'lib/types';
import { useSnackbar } from 'lib/hooks/useSnackbar';
import { findObjectsDiffs } from 'lib/utils/findObjectsDiffs';
import { arraysAreEqual } from 'lib/utils/arrayСomparison';
import { Icon20AddCircle } from '@vkontakte/icons';
import dayjs from 'dayjs';
import { replaceEmptyWithNull } from 'lib/utils/replaceEmptyWithNull';
import {
  IRanking,
  IRankingCity,
  IRankingEvent,
} from './types.rankings';
import { rankingBlank } from './constants.rankings';
import { IEventEdit } from '../Events/types.events';
import { ADMIN_RANKING_ROUTE } from 'lib/constants';
import useFetch from 'lib/hooks/useFetch';
import useFormItems from 'lib/hooks/useFormItems';
import EventsRankingCards from './RankingCards';
import Events from 'components/Events/Events';
import { IType } from 'components/directories/TypesDirectory/types.typesDirectory';
import ChipsSelectComponent from 'components/common/ChipsSelectComponent';
import PanelTitle from 'components/common/PanelTitle';

const RankingEdit: FC = () => {
  const now = dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss');
  const [rankingData, setRankingData] = useState<IRanking>(rankingBlank);
  const { fetchData: patchData } = useFetch();
  const { data, loading, fetchData } = useFetch<IRanking>();
  const {
    data: categoryOptionsData,
    loading: categoryOptionsLoading,
    fetchData: categoryOptionsFetch,
  } = useFetch<ApiResponse<IType[]>>();
  const {
    data: eventData,
    loading: eventLoading,
    fetchData: fetchEvent,
  } = useFetch<IEventEdit>();
  const {
    data: citiesData,
    loading: citiesLoading,
    fetchData: fetchCities,
  } = useFetch<ApiResponse<ICity[]>>();
  const { formData } = useFormItems<IRanking>({
    initialValues: rankingData,
  });
  const navigate = useNavigate();
  const { rankingId, methodType } = useParams();
  const { setSnackbarContent } = useSnackbar();
  const [openModal, setOpenModal] = useState<string>('');
  const [newCategory, setNewCategory] = useState<{
    id: string | null;
    name: string;
  }>({
    id: '',
    name: '',
  });
  const [allCategories, setAllCategories] = useState<IType[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<IChip[]>();
  const [isNewChecked, setIsNewChecked] = useState<boolean>(false);
  const [newEventsObj, setNewEventsObj] = useState<IRankingEvent[]>(
    [],
  );
  const [checkedEvents, setCheckedEvents] = useState<string[]>([]);
  const [newCities, setNewCities] = useState<IRankingCity[]>([]);
  const [forceUpdate, setForceUpdate] = useState(0);

  const {
    currentValues: currentCities,
    valuesSearch: citiesSearch,
    setValuesSearch: setCitiesSearch,
    valueListInnerRef: cityListInnerRef,
    valuesRootRef: citiesRootRef,
    valuesSentryRef: citiesSentryRef,
  } = useInfiniteScroll<ICity>({
    data: citiesData?.results || [],
    fetcher: (currentPage, search) => {
      fetchCities({
        path: `/geoposition/?page=${currentPage - 1}&page_size=30${search && `&q=${search}`}`,
        method: 'get',
      });
    },
    isLoading: citiesLoading,
    hasNextPage: !!citiesData?.next,
  });

  const rerenderChipsSelectComponent = () => {
    setForceUpdate((prevState) => prevState + 1);
  };

  const handleSave = async () => {
    if (methodType === 'addNew') {
      // eslint-disable-next-line
      const { id, ...diffData } = formData;

      diffData.is_active = isNewChecked;
      diffData.category_id = newCategory.id;
      diffData.cities = newCities.map((city) => city.id);
      diffData.events = newEventsObj.map((event) => event.id);

      const addPromise = patchData({
        path: `/ranking/rankings/`,
        method: 'post',
        body: replaceEmptyWithNull(diffData),
        onSuccessMessage: 'Успешно добавлено',
      });

      const response = await addPromise;
      const error = response?.errorData?.response?.data?.detail;

      if (error?.code) {
        setSnackbarContent({
          type: 'error',
          message: error.error_message,
        });
      } else {
        await navigate(ADMIN_RANKING_ROUTE);
      }
    }

    if (methodType === 'edit') {
      const diffObject = findObjectsDiffs(formData, rankingData);
      const diffKeys = Object.keys(diffObject);
      // eslint-disable-next-line
      const diffData: Record<string, any> = {};

      if (diffKeys.length !== 0) {
        diffKeys.forEach((key) => {
          diffData[key] = formData[key as keyof IRanking];
        });
      }

      if (rankingData?.category_id !== newCategory.id)
        diffData.category_id = newCategory.id;

      if (
        !arraysAreEqual(
          rankingData?.cities,
          newCities.map((city) => city.id),
        )
      ) {
        diffData.cities = newCities.map((city) => city.id);
      }

      if (
        !arraysAreEqual(
          rankingData?.events,
          newEventsObj.map((event) => event.id),
        )
      ) {
        diffData.events = newEventsObj.map((event) => event.id);
      }

      if (rankingData?.is_active !== isNewChecked)
        diffData.is_active = isNewChecked;

      if (Object.keys(diffData).length > 0) {
        const editPromise = patchData({
          path: `/ranking/rankings/${rankingId}/`,
          method: 'patch',
          body: replaceEmptyWithNull(diffData),
          onSuccessMessage: 'Изменения сохранены',
        });

        const response = await editPromise;
        const error = response?.errorData?.response?.data?.detail;

        if (error?.code) {
          setSnackbarContent({
            type: 'error',
            message: error.error_message,
          });
        } else {
          await fetchDataFunction();
        }
      }
    }
  };

  const handleIsNewCheckedChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsNewChecked(event.target.checked);
  };

  const handleDeleteEvent = (index: number) => {
    const updatedEventsObj = [...newEventsObj];
    updatedEventsObj.splice(index, 1);
    setNewEventsObj(updatedEventsObj);
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    if (!value) {
      setNewCategory({
        id: null,
        name: '',
      });

      return;
    }

    const newCategoryObj = allCategories.find(
      (element) => element.name === value,
    );

    if (newCategoryObj) {
      setNewCategory({
        id: newCategoryObj.id,
        name: newCategoryObj.name,
      });
    }
  };

  const fetchEventDetailedData = async (eventId: string) => {
    const eventExists = newEventsObj.some((event) => event.id === eventId);

    if (!eventExists) {
      await fetchEvent({
        path: `/search/events/${eventId}`,
        method: 'get',
      });
    } else {
      setSnackbarContent({
        type: 'error',
        message: 'Мероприятие уже добавлено',
      });
    }
  };

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));
  const handleAddSelectedEvents = async (checkedEvents: string[]) => {
    setOpenModal('');
    if (checkedEvents?.length > 0) {
      for (const eventId of checkedEvents) {
        await fetchEventDetailedData(eventId);
        await delay(1);
      }
    }
  };

  const fetchDataFunction = () => {
    if (methodType === 'edit') {
      fetchData({
        path: `/ranking/rankings/${rankingId}/?start_datetime__from=${dayjs(now).format('YYYY-MM-DDTHH:mm:ss')}`,
        method: 'get',
      });
    }
  };

  useEffect(() => {
    if (!eventLoading && eventData) {
      setNewEventsObj((prev) => [
        ...prev,
        {
          id: eventData.id,
          name: eventData.name,
          preview_url: eventData.preview_url,
          category_id: eventData.category?.id || '',
          category_name: eventData.category?.name || 'Тип не указан',
          rating: eventData?.rating || 0,
        },
      ]);
    }
  }, [eventData, eventLoading]);

  useEffect(() => {
    if (!categoryOptionsLoading && categoryOptionsData?.results) {
      setAllCategories(categoryOptionsData.results);

      const newCategoryOptions = categoryOptionsData.results.map((item) => ({
        label: item.name,
        value: item.name,
      }));
      setCategoryOptions(newCategoryOptions);
    }
  }, [categoryOptionsLoading, categoryOptionsData]);

  useEffect(() => {
    fetchDataFunction();

    categoryOptionsFetch({
      path: `/event/categories/?is_active=true&page_size=50`,
      method: 'get',
    });
  }, [methodType, rankingId]);

  useEffect(() => {
    if (methodType === 'edit' && !loading && data) {
      setRankingData(data);
    }
  }, [methodType, loading, data]);

  useEffect(() => {
    if (rankingData?.category) {
      setNewCategory(rankingData.category);
    }

    setIsNewChecked(rankingData.is_active);

    if (rankingData?.events_obj) {
      setNewEventsObj(rankingData.events_obj);
    }
  }, [rankingData]);

  useEffect(() => {
    if (rankingData.cities_obj) {
      setNewCities(
        rankingData.cities_obj.map(({ id, name }) => ({ id, name })),
      );
    }
    rerenderChipsSelectComponent();
  }, [rankingData.cities_obj]);

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <>
          <PanelTitle arrowColor="#99A2AD">
            <Title className="text-color-black">
              {methodType === 'edit'
                ? `Ранжирование и закрепление`
                : 'Добавить ранжирование и закрепление'}
            </Title>
            {methodType === 'edit' && (
              <Title className="inline text-color-steel-gray-500">
                {rankingData.category?.name || 'Выберите тип'}
              </Title>
            )}
          </PanelTitle>
          <Group className="custom-scrollbar">
            <FormItem top="Тип" htmlFor="category">
              <Select
                className="vkui-select"
                id="category"
                name="category"
                allowClearButton
                placeholder="Выберите тип"
                value={newCategory.name as string}
                onChange={handleSelectChange}
                options={categoryOptions || []}
              />
            </FormItem>
            <FormItem top="Город">
              <ChipsSelectComponent
                key={forceUpdate}
                hasNextPage={!!citiesData?.next}
                loaderRef={citiesSentryRef}
                listRef={citiesRootRef}
                listInnerRef={cityListInnerRef}
                onInput={(e) => {
                  setCitiesSearch(e.target.value.trim());
                }}
                inputValue={citiesSearch}
                chipClassName="max-w-[170px] truncate"
                placeholder="Выберите города"
                values={newCities.map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
                onChange={(selectedCities: IChip[]) => {
                  setNewCities(
                    selectedCities.map(({ label, value }) => ({
                      name: label,
                      id: value,
                    })),
                  );
                }}
                options={currentCities.map((city) => ({
                  label: city.name,
                  value: city.id,
                }))}
              />
            </FormItem>
            <FormItem className="py-0 mt-3 mb-2 ml-3 w-32">
              <Checkbox
                checked={isNewChecked}
                onChange={handleIsNewCheckedChange}
              >
                Активно
              </Checkbox>
            </FormItem>
            <FormItem>
              <Button
                mode="secondary"
                size="l"
                appearance="accent"
                onClick={() => setOpenModal('add')}
                before={<Icon20AddCircle width={18} />}
              >
                Добавить мероприятия
              </Button>
            </FormItem>
            <EventsRankingCards
              cards={newEventsObj}
              oldCards={rankingData?.events_obj || []}
              setCards={setNewEventsObj}
              onDelete={handleDeleteEvent}
            />
            <Div>
              <ButtonGroup align="right" stretched>
                {methodType === 'edit' ? (
                  <Button
                    className="vkui-edit-button-primary"
                    mode="primary"
                    size="m"
                    appearance="accent"
                    onClick={handleSave}
                  >
                    Сохранить
                  </Button>
                ) : (
                  <>
                    <Button
                      onClick={() => navigate(ADMIN_RANKING_ROUTE)}
                      className="vkui-edit-button-secondary"
                      mode="secondary"
                      size="m"
                      appearance="accent"
                    >
                      Назад
                    </Button>
                    <Button
                      className="vkui-edit-button-primary"
                      mode="primary"
                      size="m"
                      appearance="accent"
                      onClick={handleSave}
                    >
                      Добавить
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </Div>
          </Group>
        </>
      )}
      <ModalRoot activeModal={openModal}>
        <ModalPage
          id="add"
          onClose={() => setOpenModal('')}
          size={1200}
          dynamicContentHeight={true}
        >
          <div className="max-h-[80vh] overflow-y-auto p-4">
            <Events
              onCheckboxesChange={(checkedElements: string[]) => {
                setCheckedEvents(checkedElements);
              }}
            />
            <ButtonGroup className="pr-3 pb-3" align="right" stretched>
              <Button
                className="vkui-edit-button-primary "
                mode="primary"
                size="m"
                align="right"
                onClick={() => handleAddSelectedEvents(checkedEvents)}
                disabled={checkedEvents.length === 0}
              >
                Добавить
              </Button>
            </ButtonGroup>
          </div>
        </ModalPage>
      </ModalRoot>
    </>
  );
};

export default RankingEdit;
