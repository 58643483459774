import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Div,
  Group,
  Text,
  Title,
  FormItem,
  Spinner,
  ModalRoot,
  ModalCard,
  View,
  Panel,
  PanelHeader,
  Tabs,
  TabsItem,
} from '@vkontakte/vkui';
import {
  Icon16PaymentCardOutline,
  Icon16CancelCircleOutline,
  Icon16RoubleArrowBackwardOutline,
  Icon16Replay,
} from '@vkontakte/icons';
import { orderBlank } from './constants.orders';
import { IOrder } from './types.orders';
import { useSnackbar } from 'lib/hooks/useSnackbar';
import { formatPrice } from 'lib/utils/formatPrice';
import { parseDateFrom } from 'lib/utils/date.utils';
import useFetch from 'lib/hooks/useFetch';
import PanelTitle from 'components/common/PanelTitle';
import EventFormItem from 'components/common/EventFormItem';
import { ADMIN_EVENTS_ROUTE } from 'lib/constants';
import { validateEmail } from 'lib/utils/inputVaildation';
import OrderTickets from './OrderTickets';

const OrdersEdit: FC = () => {
  const [orderData, setOrderData] = useState<IOrder>(orderBlank);
  const [openModal, setOpenModal] = useState<string>('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const { orderId, methodType } = useParams();
  const [newEmail, setNewEmail] = useState<string | null>(null);
  const [selectedTab, setSelectedTab] = useState<string>(() => {
    return sessionStorage.getItem('selectedOrderTab') || 'info';
  });
  const { setSnackbarContent } = useSnackbar();
  const {
    number,
    event_name,
    created_at,
    buyer,
    user_id,
    city,
    seance,
    provider,
    pdf,
    order_price,
    status,
    client_order_id,
    event_id,
    order_fee,
    count_tickets,
  } = orderData;
  const { data, loading, fetchData } = useFetch<IOrder>();
  const { fetchData: sentTicket } = useFetch();

  const handleSendTicket = async () => {
    if (newEmail) {
      try {
        await sentTicket({
          path: `/order/orders/resend/`,
          method: 'post',
          body: {
            order_id: orderId,
            email: newEmail,
          },
          onSuccessMessage:
            'Билет отправлен на указанный адрес электронной почты',
        });

        setOpenModal('');
        setNewEmail(null);
      } catch (error) {
        setSnackbarContent({
          type: 'error',
          message: 'Ошибка при отправке билета',
        });
      }
    }
  };

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    sessionStorage.setItem('selectedOrderTab', selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    setIsEmailValid(validateEmail(newEmail ?? ''));
  }, [newEmail]);

  useEffect(() => {
    fetchData({ path: `/order/orders/${orderId}/`, method: 'get' });
  }, []);

  useEffect(() => {
    if (methodType === 'edit') {
      if (!loading && data) {
        setOrderData(data);
      }
    }
  }, [loading]);

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <>
          <PanelTitle>
            <Title className="text-color-black">Детали заказа</Title>
            <span className="text-color-black text-3xl">-</span>
            <Title className="inline text-color-steel-gray-500">{number}</Title>
          </PanelTitle>
          <View activePanel="panel" className="vkui-tabs">
            <Panel id="panel">
              <PanelHeader>
                <Tabs layoutFillMode={'shrinked'} className="flex pl-5">
                  <TabsItem
                    selected={selectedTab === 'info'}
                    onClick={() => {
                      handleTabChange('info');
                    }}
                    id="tab-info"
                    aria-controls="tab-content-info"
                  >
                    Информация
                  </TabsItem>
                  <TabsItem
                    selected={selectedTab === 'tickets'}
                    onClick={() => {
                      handleTabChange('tickets');
                    }}
                    id="tab-tickets"
                    aria-controls="tab-content-tickets"
                    after={
                      <div
                        style={{ color: '#ffffff', backgroundColor: '#5181B8' }}
                        className="flex items-center justify-center rounded-full w-5 h-5 text-xs"
                      >
                        {count_tickets}
                      </div>
                    }
                  >
                    Билеты
                  </TabsItem>
                </Tabs>
              </PanelHeader>
              {selectedTab === 'info' && (
                <Group className="custom-scrollbar">
                  <FormItem top="Статус">
                    {status === 'cancelled' && (
                      <Text className="text-base bg-red-100 text-red-500 flex justify-between gap-2 items-center p-1 rounded w-fit">
                        {'Отменен'}
                        <Icon16CancelCircleOutline
                          width={18}
                          height={18}
                          color={'rgb(239 68 68)'}
                        />
                      </Text>
                    )}
                    {status === 'refunded' && (
                      <Text className="text-base bg-orange-100 text-orange-500 flex justify-between gap-2 items-center p-1 rounded w-fit">
                        {'Возвращен'}
                        <Icon16Replay
                          width={18}
                          height={18}
                          color={'rgb(251 146 60)'}
                        />
                      </Text>
                    )}
                    {status === 'partially_refunded' && (
                      <Text className="text-base bg-yellow-100 text-yellow-500 flex justify-between gap-2 items-center p-1 rounded w-fit">
                        {'Частично возвращен'}
                        <Icon16RoubleArrowBackwardOutline
                          width={18}
                          height={18}
                          color={'#facc15'}
                        />
                      </Text>
                    )}
                    {status === 'paid' && (
                      <Text className="text-base bg-green-100 text-green-600 flex justify-between gap-2 items-center p-1 rounded w-fit">
                        {'Оплачен'}
                        <Icon16PaymentCardOutline
                          width={18}
                          height={18}
                          color={'rgb(34 197 94)'}
                        />
                      </Text>
                    )}
                    {!status && (
                      <Text className="text-base ">{'Статус не указан'}</Text>
                    )}
                  </FormItem>
                  <FormItem top="Клиентский номер заказа">
                    <Text className="text-base text-color-text-primary">
                      {client_order_id || '-'}
                    </Text>
                  </FormItem>
                  <FormItem top="Название мероприятия">
                    <Text className="text-base text-color-text-primary">
                      {event_name || '-'}
                    </Text>
                    <Button
                      mode="secondary"
                      href={`${ADMIN_EVENTS_ROUTE}/edit/${event_id}`}
                      target="_blank"
                      size="l"
                      appearance="accent"
                      className="mt-3"
                    >
                      Посмотреть мероприятие
                    </Button>
                  </FormItem>
                  <FormItem top="Дата заказа">
                    <Text className="text-base text-color-text-primary">
                      {created_at !== null && typeof created_at === 'string'
                        ? parseDateFrom({
                            date: created_at,
                            format: 'daytime',
                            utc: false,
                          })
                        : '-'}
                    </Text>
                  </FormItem>
                  <FormItem top="Сумма заказа">
                    <Text className="text-base text-color-text-primary">
                      {order_price ? formatPrice(order_price) : '-'}
                    </Text>
                  </FormItem>
                  <FormItem top="Комиссия">
                    <Text className="text-base text-color-text-primary">
                      {order_fee ? formatPrice(order_fee) : '-'}
                    </Text>
                  </FormItem>
                  <FormItem top="ФИО">
                    <Text className="text-base text-color-text-primary">
                      {buyer?.name || '-'}
                    </Text>
                  </FormItem>
                  <FormItem top="ID пользователя">
                    <Text className="text-base text-color-text-primary">
                      {user_id || '-'}
                    </Text>
                  </FormItem>
                  <FormItem top="Номер">
                    <Text className="text-base text-color-text-primary">
                      {buyer?.phone || '-'}
                    </Text>
                  </FormItem>
                  <FormItem top="E-mail">
                    <Text className="text-base text-color-text-primary">
                      {buyer?.email || '-'}
                    </Text>
                  </FormItem>
                  <FormItem top="Поставщик">
                    <Text className="text-base text-color-text-primary">
                      {provider || '-'}
                    </Text>
                  </FormItem>
                  <FormItem top="Город">
                    <Text className="text-base text-color-text-primary">
                      {city || '-'}
                    </Text>
                  </FormItem>
                  <FormItem top="Дата мероприятия">
                    <Text className="text-base text-color-text-primary">
                      {seance && typeof seance.start_date === 'string'
                        ? parseDateFrom({
                            date: seance.start_date,
                            format: 'daytime',
                            utc: false,
                          })
                        : '-'}
                    </Text>
                  </FormItem>
                  <Div>
                    <ButtonGroup align="right" stretched>
                      <Button
                        className="vkui-edit-button-secondary"
                        mode="secondary"
                        size="m"
                        appearance="accent"
                        disabled={pdf && pdf !== '' ? false : true}
                        onClick={() => {
                          if (pdf && pdf !== '') {
                            window.open(pdf, '_blank');
                          }
                        }}
                      >
                        PDF Билета
                      </Button>
                      <Button
                        className="vkui-edit-button-primary"
                        mode="primary"
                        size="m"
                        appearance="accent"
                        onClick={() => setOpenModal('sendTicket')}
                      >
                        Отправить билет
                      </Button>
                    </ButtonGroup>
                  </Div>
                </Group>
              )}
              {selectedTab === 'tickets' && (
                <Group className="custom-scrollbar">
                  <OrderTickets />
                  <Div>
                    <ButtonGroup align="right" stretched>
                      <Button
                        className="vkui-edit-button-secondary"
                        mode="secondary"
                        size="m"
                        appearance="accent"
                        disabled={pdf && pdf !== '' ? false : true}
                        onClick={() => {
                          if (pdf && pdf !== '') {
                            window.open(pdf, '_blank');
                          }
                        }}
                      >
                        PDF Билета
                      </Button>
                      <Button
                        className="vkui-edit-button-primary"
                        mode="primary"
                        size="m"
                        appearance="accent"
                        onClick={() => setOpenModal('sendTicket')}
                      >
                        Отправить билет
                      </Button>
                    </ButtonGroup>
                  </Div>
                </Group>
              )}
            </Panel>
          </View>
        </>
      )}
      <ModalRoot activeModal={openModal}>
        <ModalCard id="sendTicket" onClose={() => setOpenModal('')}>
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black" level="2">
              Отправить билет
            </Title>
            <EventFormItem
              id="new-uniuque-user-ml"
              className="vkui-input"
              top="Укажите электронную почту"
              name="newEmail"
              value={newEmail || ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setNewEmail(event.target.value)
              }
              placeholder="example@mail.ru"
              isRequired={true}
              isError={false}
              errorItems={[]}
              error={''}
              autoComplete="off"
            />
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="l"
                onClick={() => setOpenModal('')}
              >
                Отмена
              </Button>
              <Button
                className="vkui-edit-button-primary"
                mode="primary"
                size="l"
                onClick={() => handleSendTicket()}
                disabled={!isEmailValid}
              >
                Отправить
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard>
        {/* <ModalCard id="closeReviews" onClose={() => setOpenModal('')}>
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black" level="2">
              Закрыть отзывы
            </Title>
            <Text className="text-color-gray-600">
              У мероприятия будут закрыты отзывы
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={() => setOpenModal('')}
              >
                Отмена
              </Button>
              <Button
                mode="primary"
                className="vkui-edit-button-primary"
                size="m"
                onClick={() => console.log('hidden')}
              >
                Закрыть
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard> */}
        {/* <ModalCard id="closeSelling" onClose={() => setOpenModal('')}>
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black" level="2">
              Закрыть продажу билетов
            </Title>
            <Text className="text-color-gray-600">
              У выбранных сеансов будет закрыта продажа билетов
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={() => setOpenModal('')}
              >
                Отмена
              </Button>
              <Button
                mode="primary"
                className="vkui-edit-button-primary"
                size="m"
                onClick={() => console.log('hidden')}
              >
                Закрыть
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard> */}
        {/* <ModalCard id="openSelling" onClose={() => setOpenModal('')}>
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black" level="2">
              Открыть продажу билетов
            </Title>
            <Text className="text-color-gray-600">
              У выбранных сеансов будет открыта продажа билетов
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={() => setOpenModal('')}
              >
                Отмена
              </Button>
              <Button
                mode="primary"
                className="vkui-edit-button-primary"
                size="m"
                onClick={() => console.log('hidden')}
              >
                Закрыть
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard> */}
        {/* <ModalCard id="closeAllSelling" onClose={() => setOpenModal('')}>
          <Div className="p-0 flex flex-col gap-2">
            <Title className="text-color-black" level="2">
              Закрыть продажи на все мероприятия
            </Title>
            <Text className="text-color-gray-600">
              Продажи на все мероприятия будут закрыты
            </Text>
            <ButtonGroup className="pt-3" align="right" stretched>
              <Button
                className="vkui-edit-button-secondary"
                mode="secondary"
                size="m"
                onClick={() => setOpenModal('')}
              >
                Отмена
              </Button>
              <Button
                mode="primary"
                className="vkui-edit-button-primary"
                size="m"
                onClick={() => console.log('hidden')}
              >
                Закрыть
              </Button>
            </ButtonGroup>
          </Div>
        </ModalCard> */}
      </ModalRoot>
    </>
  );
};

export default OrdersEdit;
